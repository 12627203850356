import React from 'react';

import PropTypes from 'prop-types';

import { map } from 'lodash';

import Carousel from '@commons/components/Carousel';
import Detalhes from '@commons/components/DetalhesLote';

import { Container, Image, DetailsContainer } from './styles';

const DetalhesCarousel = ({ lote }) => {
  const fotoPath = (lote.tipo_foto === 'Lotes')
    ? `${process.env.REACT_APP_API_URL.slice(0, -4)}/images/lotes/`
    : `${process.env.REACT_APP_API_URL.slice(0, -4)}/images/lotes/bens/`;

  return (
    <>
      <Container>
        <Carousel>
          <DetailsContainer>
            <Detalhes noTitle lote={lote} />
          </DetailsContainer>
          {!!lote.fotos.length &&
            map(lote.fotos, (item, index) => {
              let fotoUrl = (lote.tipo_foto === 'Lotes')
                ? `${fotoPath}${item.arquivo}`
                : `${fotoPath}${item.nome_storage}.${item.extensao}`;

              return <Image key={index} src={fotoUrl} alt="Imagem " />;
            })}
        </Carousel>
      </Container>
    </>
  );
};

DetalhesCarousel.propTypes = {
  lote: PropTypes.object,
};

export default DetalhesCarousel;
